import { useState } from "react";
import { DeleteModal } from "../../components/modal/DeleteModal.tsx";
import { DeleteResourceModalContent } from "../../components/modal/DeleteResourceModal.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { deleteIpAddressMutation, ipQueryAtom } from "../network/ipAddress/apiOperations.ts";
import type { ManagedServiceLoc } from "./servicesQuery.ts";
import type { DeleteServiceMutation } from "./ServiceView.tsx";

export interface ServiceDeleteModalProps {
    managedService: ManagedServiceLoc;
    deleteMutation: DeleteServiceMutation;
}

export function ServiceDeleteModal({ managedService, deleteMutation }: ServiceDeleteModalProps) {
    //#region Hooks
    const delIpMutation = useStandardMutation(deleteIpAddressMutation);
    const { uuid, display_name, properties } = managedService;
    const ips = useSuspenseQueryAtom(ipQueryAtom(properties.location));
    const attachedPublicIp = [...ips.values()].find(
        (ip) => ip?.assigned_to_resource_type === "service" && ip.assigned_to === uuid,
    );

    const [deleteFloatingIp, setDeleteFloatingIp] = useState<string>("");
    //#endregion

    const deleteAlsoIp = deleteFloatingIp === "yes" && attachedPublicIp;

    async function deleteAction() {
        await deleteMutation.mutateAsync({ uuid: uuid });
        if (deleteAlsoIp) {
            await delIpMutation.mutateAsync({ location: properties.location, address: attachedPublicIp.address });
        }
    }

    return (
        <DeleteModal
            title="Delete Resource"
            modalAction={deleteAction}
            isActionDisabled={attachedPublicIp && deleteFloatingIp === ""}
        >
            <DeleteResourceModalContent
                ipAddress={attachedPublicIp}
                value={deleteFloatingIp}
                onChange={(value: string) => setDeleteFloatingIp(value)}
            >
                <p className="pb-3">
                    Deleting a service "{display_name}" will permanently remove it and will not be accessible after
                    that.
                </p>
                <p>Deleting a service will also delete your backups and the service cannot be restored after that.</p>
            </DeleteResourceModalContent>
        </DeleteModal>
    );
}
